"use client";

import * as React from "react";

import {
  Button,
  Command,
  CommandGroup,
  CommandItem,
  CommandList,
  Icon,
  Popover,
  PopoverContent,
  PopoverTrigger,
  Separator,
} from "@revana/ui";
import clsx from "clsx";
import Link from "next/link";

import { useStoreContext } from "@web/libs/StoreProvider";

import { APP_ROUTES } from "./constants";
import SignoutButton from "../SignoutButton";

const StoreSelector: React.FC<{ isNavOpen: boolean }> = ({ isNavOpen }) => {
  const { stores, currentStoreId } = useStoreContext();
  const options = stores.map((store) => ({
    label: store.name,
    value: store.id.toString(),
    domain: store.domain,
  }));
  const [value, setValue] = React.useState(currentStoreId.toString());
  const [open, setOpen] = React.useState(false);

  const currentStore =
    stores.find((store) => store.id.toString() === value) ?? stores[0];

  if (!currentStore) {
    return null;
  }

  const firstCharacter = currentStore.name.charAt(0);

  return (
    <Popover open={open} onOpenChange={setOpen}>
      <PopoverTrigger asChild>
        <Button
          variant="outline"
          role="combobox"
          aria-expanded={open}
          className={clsx("h-auto justify-between gap-4 transition-all", {
            "border-transparent p-0": !isNavOpen,
            "border-border p-2": isNavOpen,
          })}
        >
          <div className="flex w-full max-w-full items-center gap-3">
            <div
              className={clsx(
                "flex aspect-square items-center justify-center rounded-md bg-primary p-4 font-bold text-white transition-all",
                {
                  "h-4 text-sm": isNavOpen,
                  "h-10 text-lg": !isNavOpen,
                }
              )}
            >
              {firstCharacter}
            </div>

            <div
              className={clsx(
                "flex flex-col truncate text-left transition-all",
                {
                  "w-full opacity-100": isNavOpen,
                  "w-0 opacity-0": !isNavOpen,
                }
              )}
            >
              <div className="text-sm font-bold text-card-foreground">
                {currentStore.name}
              </div>
              <div className="truncate text-xs text-card-foreground/60">
                {currentStore.domain}
              </div>
            </div>
            <div
              className={clsx(
                "ml-auto flex shrink-0 grow justify-end transition-all",
                {
                  "opacity-100": isNavOpen,
                  "opacity-0": !isNavOpen,
                }
              )}
            >
              <Icon
                name="chevrons-up-down"
                className="h-4 w-4 text-card-foreground"
              />
            </div>
          </div>
        </Button>
      </PopoverTrigger>
      <PopoverContent className="w-full p-0" align="start">
        <Command>
          <CommandList>
            <CommandGroup className="p-2">
              {options.map((option) => (
                <CommandItem
                  key={option.value}
                  value={option.value}
                  onSelect={(newValue) => {
                    setOpen(false);
                    if (newValue !== value) {
                      setValue(newValue);
                    }
                  }}
                >
                  <div className="flex w-full cursor-pointer items-center justify-between gap-6">
                    <div>
                      <div className="text-sm font-bold">{option.label}</div>
                      <div className="text-xs text-card-foreground/60">
                        {option.domain}
                      </div>
                    </div>
                    {option.value === value && (
                      <Icon
                        name="badge-check"
                        className="h-4 w-4 text-primary"
                      />
                    )}
                  </div>
                </CommandItem>
              ))}
            </CommandGroup>
          </CommandList>
        </Command>
        <Separator />
        <Command>
          <CommandList>
            <CommandGroup className="p-2">
              <Link href={APP_ROUTES["account-settings"]}>
                <Button size="sm" className="w-full" variant="tertiary">
                  Account Settings
                </Button>
              </Link>
              <SignoutButton className="mt-2 w-full" size="sm" />
            </CommandGroup>
          </CommandList>
        </Command>
      </PopoverContent>
    </Popover>
  );
};

export default StoreSelector;
