"use client";

import { useCallback, useState } from "react";

import { mergeDeep } from "@web/utils";
import { isObject } from "@web/utils/typeUtils";

export type IUseArraySetValue<T> = React.Dispatch<React.SetStateAction<T>>;
export type IUseArrayUpdateIndex<TValue, TReturnValue> = (
  index: number
) => (
  newValue: Partial<TValue> | TValue,
  spread?: boolean,
  doMergeDeep?: boolean
) => TReturnValue;
export type IUseArrayAppend<TValue, TReturnValue> = (
  newValue: TValue | TValue[],
  appendEnd?: boolean
) => TReturnValue;
export type IUseArrayRemoveIndex<TReturnValue> = (index: number) => {
  newArray: TReturnValue;
  removedValue: TReturnValue;
};

// useArray initialized with value
export interface IUseArrayNonNullableReturnType<TValue> {
  value: TValue[];
  setValue: IUseArraySetValue<TValue[]>;
  updateIndex: IUseArrayUpdateIndex<TValue, TValue[]>;
  append: IUseArrayAppend<TValue, TValue[]>;
  removeIndex: IUseArrayRemoveIndex<TValue[]>;
}

// useArray initialized with undefined
export interface IUseArrayNullableReturnType<TValue> {
  value: TValue[] | undefined;
  setValue: IUseArraySetValue<TValue[] | undefined>;
  updateIndex: IUseArrayUpdateIndex<TValue, TValue[] | undefined>;
  append: IUseArrayAppend<TValue, TValue[] | undefined>;
  removeIndex: IUseArrayRemoveIndex<TValue[] | undefined>;
}

// TODO: Figure out why this doesn't satisfy the implementation signature
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
function useArray<TValue>(
  initial: TValue[]
): IUseArrayNonNullableReturnType<TValue>;
// eslint-disable-next-line no-redeclare
function useArray<TValue>(
  initial?: undefined
): IUseArrayNullableReturnType<TValue>;
// eslint-disable-next-line no-redeclare
function useArray<TValue>(initial?: TValue[] | undefined) {
  /* Custom hook to manage an array.

  Args:
    initial: initial value for array
  
  Returns:
    value: value of the array
    setValue: setter for value
    updateIndex: update a specific index (or element) of the array and returns the new
            array
    append: appends a new item to the beginning or end of the array and returns the new
            array
   */

  const [value, setValue] = useState(initial);

  const updateIndex = useCallback<
    IUseArrayUpdateIndex<TValue, TValue[] | undefined>
  >(
    (index) =>
      (newValue, spread = true, doMergeDeep = false) => {
        /* Update a specific index of the array.

      Is a double arrow function with the first arrow as index and the second arrow for 
      the new value. The spread bool is if we should spread the new value into the 
      existing row. This is useful for arrays of dictionaries. If you have an array with
      ints or strings, spread=false should be used.

      Example:

      const {value, setValue, updateIndex} = useArray([]);

      value.map((v,i) => <RenderRow updateIndex={updateIndex(i)} /> )

      function RenderRow({updateIndex}){
        const callback = () => updateIndex({key: newValue})
      }

      Args:
        index: index of array to be changed
        newValue: new value for the element
        spread: bool for if the new value should be spread after the old value. If false,
          then overwrites the value for the entire index.
        doMergeDeep: do recursive deep merge
        
      Returns:
        value: updated array, very useful for continuing computation
      */
        if (value === undefined) {
          return undefined;
        }
        const _value = Array.from(value);

        const valueAtIndex = value[index];
        if (spread && isObject(valueAtIndex) && isObject(newValue)) {
          if (doMergeDeep) _value[index] = mergeDeep(valueAtIndex, newValue);
          else _value[index] = { ...value[index], ...newValue } as TValue;
        } else {
          _value[index] = newValue as TValue;
        }
        setValue(_value);
        return _value;
      },
    [value]
  );

  const removeIndex = useCallback<IUseArrayRemoveIndex<TValue[] | undefined>>(
    /* Remove an index of the array.

    Args:
      index: index of the element to remove

    Returns:
      newArray: new array without the removed element, very useful for continuing computation
      removedValue: value of the removed index
     */
    (index) => {
      if (value === undefined) {
        return {
          newArray: undefined,
          removedValue: undefined,
        };
      }
      const _value = Array.from(value);
      const removedValue = _value.splice(index, 1);
      setValue(_value);
      return { newArray: _value, removedValue: removedValue };
    },
    [value]
  );

  const append = useCallback<IUseArrayAppend<TValue, TValue[] | undefined>>(
    (newValue, appendEnd = true) => {
      /* Append to the beginning or end of an array

    Args:
      newValue: new value to append
      appendEnd: bool if the value should be the first or last element
    
    Returns:
      value: new array, very useful for continuing computation
     */
      if (value === undefined) {
        return undefined;
      }

      let _value;
      if (Array.isArray(newValue)) {
        if (appendEnd) _value = value.concat(newValue);
        else _value = newValue.concat(value);
      } else if (appendEnd) _value = value.concat([newValue]);
      else _value = [newValue].concat(value);
      setValue(_value);
      return _value;
    },
    [value]
  );

  return {
    value,
    setValue,
    updateIndex,
    append,
    removeIndex,
  };
}

export default useArray;
