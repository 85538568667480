"use client";
import React, { createContext, use, useContext, useEffect } from "react";

import type { Store } from "@revana/db";
import { type Encode, useCookieState } from "use-cookie-state";

import { api } from "@web/trpc/client";
import { useArray } from "@web/utils";

export interface IStoreContext {
  stores: Store[];
  currentStoreId: number;
  setStores: React.Dispatch<React.SetStateAction<Store[]>>;
  setCurrentStoreId: (value: number, encode?: Encode) => void;
}

const StoreContext = createContext<IStoreContext | null>(null);

export function useStoreContext() {
  const storeContext = useContext(StoreContext);

  if (!storeContext) {
    throw new Error("useModelsContext must be used within a ModelsProvider");
  }

  return storeContext;
}

const StoreProvider: React.FC<{
  children: React.ReactNode;
  initialStores: Promise<Store[]>;
}> = ({ children, initialStores: promiseInitialStores }) => {
  const initialStores = use(promiseInitialStores);
  const { data } = api.store.getUserStores.useQuery(undefined, {
    initialData: initialStores,
  });
  const { value: stores, setValue: setStores } = useArray<Store>(data);

  // due to SSR we cant set the intial state here but need useEffect for access to setting cookies.
  const [currentStoreId, setCurrentStoreId] = useCookieState(
    "currentStoreId",
    0
  );
  //set initial cookie
  useEffect(() => {
    setCurrentStoreId(Number(initialStores?.[0]?.id) ?? 0);
  }, [initialStores, setCurrentStoreId]);

  const storeContextValue = {
    stores: stores,
    currentStoreId: Number(currentStoreId),
    setStores: setStores,
    setCurrentStoreId: setCurrentStoreId,
  };

  return (
    <StoreContext.Provider value={storeContextValue}>
      {children}
    </StoreContext.Provider>
  );
};

export default StoreProvider;
