import { type IIconName } from "@revana/ui";

export function getCurrentApp(url: string): IAppInfo {
  const urlSegments = url.split("/").filter(Boolean);

  let closestMatch: { appKey: EApps; matchLength: number } = {
    appKey: EApps.DASHBOARD,
    matchLength: 0,
  };

  (Object.entries(APP_ROUTES) as [EApps, string][]).forEach(
    ([appKey, route]) => {
      const routeSegments = route.split("/").filter(Boolean);
      let matchLength = 0;

      for (
        let i = 0;
        i < Math.min(urlSegments.length, routeSegments.length);
        i++
      ) {
        if (urlSegments[i] === routeSegments[i]) {
          matchLength++;
        } else {
          break;
        }
      }

      if (
        matchLength > 0 &&
        (!closestMatch || matchLength > closestMatch.matchLength)
      ) {
        closestMatch = { appKey, matchLength };
      }
    }
  );

  return APP_INFO[closestMatch.appKey];
}

export enum EApps {
  /* Sidebar (Store) */
  DASHBOARD = "dashboard",
  CAMPAIGNS = "campaigns",
  REPORTS = "reports",
  STORE_SETTINGS = "store-settings",
  HELP = "help",

  /* Account */
  SIGNUP = "signup",
  LOGIN = "login",
  ONBOARDING = "onboarding",
  ACCOUNT_SETTINGS = "account-settings",

  /* Internal */
  ADMIN = "admin",
}

export interface IAppInfo {
  key: EApps;
  displayName: string;
  subtitle?: string;
  icon?: IIconName;
  mainRoute: string;
  route: string;
  display: boolean;
}

export const APP_ROUTES: Record<EApps, string> = {
  [EApps.DASHBOARD]: "/",
  [EApps.CAMPAIGNS]: "/campaigns",
  [EApps.REPORTS]: "/reports",
  [EApps.STORE_SETTINGS]: "/store-settings",
  [EApps.HELP]: "/help",

  /* Account */
  [EApps.SIGNUP]: "/auth/signup",
  [EApps.LOGIN]: "/auth/login",
  [EApps.ONBOARDING]: "/onboarding",
  [EApps.ACCOUNT_SETTINGS]: "/account-settings",

  /* Internal */
  [EApps.ADMIN]: "/admin",
};

export const APP_INFO: Record<EApps, IAppInfo> = {
  [EApps.DASHBOARD]: {
    key: EApps.DASHBOARD,
    displayName: "Dashboard",
    subtitle: "View your store analytics",
    icon: "circle-gauge",
    mainRoute: APP_ROUTES[EApps.DASHBOARD],
    route: APP_ROUTES[EApps.DASHBOARD],
    display: true,
  },
  [EApps.CAMPAIGNS]: {
    key: EApps.CAMPAIGNS,
    displayName: "Campaigns",
    subtitle: "Create your own AI generated music",
    icon: "megaphone",
    mainRoute: APP_ROUTES[EApps.CAMPAIGNS],
    route: APP_ROUTES[EApps.CAMPAIGNS],
    display: true,
  },
  [EApps.REPORTS]: {
    key: EApps.REPORTS,
    displayName: "Reports",
    subtitle: "View your store analytics",
    icon: "file-line-chart",
    mainRoute: APP_ROUTES[EApps.REPORTS],
    route: APP_ROUTES[EApps.REPORTS],
    display: true,
  },
  [EApps.STORE_SETTINGS]: {
    key: EApps.STORE_SETTINGS,
    displayName: "Store Settings",
    subtitle: "Store settings for Revana",
    icon: "settings",
    mainRoute: APP_ROUTES[EApps.STORE_SETTINGS],
    route: APP_ROUTES[EApps.STORE_SETTINGS],
    display: true,
  },
  [EApps.HELP]: {
    key: EApps.HELP,
    displayName: "Help",
    subtitle: "Get support from the Revana community",
    icon: "badge-help",
    mainRoute: APP_ROUTES[EApps.HELP],
    route: APP_ROUTES[EApps.HELP],
    display: true,
  },

  /* Account */
  [EApps.SIGNUP]: {
    key: EApps.SIGNUP,
    displayName: "Signup",
    subtitle: "Signup to Revana",
    mainRoute: APP_ROUTES[EApps.SIGNUP],
    route: APP_ROUTES[EApps.SIGNUP],
    display: false,
  },
  [EApps.LOGIN]: {
    key: EApps.LOGIN,
    displayName: "Login",
    subtitle: "Login to Revana",
    mainRoute: APP_ROUTES[EApps.LOGIN],
    route: APP_ROUTES[EApps.LOGIN],
    display: false,
  },
  [EApps.ONBOARDING]: {
    key: EApps.ONBOARDING,
    displayName: "Onboarding",
    subtitle: "Onboarding to Revana",
    mainRoute: APP_ROUTES[EApps.ONBOARDING],
    route: APP_ROUTES[EApps.ONBOARDING],
    display: false,
  },
  [EApps.ACCOUNT_SETTINGS]: {
    key: EApps.ACCOUNT_SETTINGS,
    displayName: "Account Settings",
    subtitle: "Account settings for Revana",
    mainRoute: APP_ROUTES[EApps.ACCOUNT_SETTINGS],
    route: APP_ROUTES[EApps.ACCOUNT_SETTINGS],
    display: false,
  },

  /* Internal */
  [EApps.ADMIN]: {
    key: EApps.ADMIN,
    displayName: "Admin",
    subtitle: "Admin panel for Revana",
    mainRoute: APP_ROUTES[EApps.ADMIN],
    route: APP_ROUTES[EApps.ADMIN],
    display: false,
  },
};

export const APPS = Object.values(APP_INFO);
