import React, { useState } from "react";

import { Icon } from "@revana/ui";
import clsx from "clsx";
import Link from "next/link";
import { usePathname } from "next/navigation";

import {
  APPS,
  getCurrentApp,
  type IAppInfo,
} from "@web/components/Navigation/constants";
import StoreSelector from "@web/components/Navigation/StoreSelector";

// import UserAccount from "../UserAccount";

const NavigationItem: React.FC<{
  isActiveApp: boolean;
  isNavOpen: boolean;
  appInfo: IAppInfo;
}> = ({ isActiveApp, isNavOpen, appInfo }) => {
  const { displayName, key, icon } = appInfo;
  const appRoute = appInfo.mainRoute || "/";

  return (
    <div className="py-3">
      <Link key={key} href={appRoute}>
        <div
          className={clsx(
            "group relative flex items-center gap-3 overflow-hidden rounded-lg border px-[10px] py-[6px] transition-all",
            {
              "border-foreground/15 bg-background shadow-sm": isActiveApp,
              "border-transparent hover:border-foreground/15": !isActiveApp,
              "max-w-10": !isNavOpen,
              "max-w-96": isNavOpen,
            }
          )}
        >
          <div
            className={clsx(
              "absolute bottom-0 left-0 top-0 my-auto aspect-square h-3/4 -translate-x-[19px] rounded-md bg-primary",
              {
                "opacity-90": isActiveApp,
                "opacity-0": !isActiveApp,
              }
            )}
          />

          {icon && (
            <div>
              <Icon
                name={icon}
                className={clsx("h-5 w-5 transition-all", {
                  "text-primary": isActiveApp,
                  "text-foreground/75 group-hover:text-foreground":
                    !isActiveApp,
                })}
              />
            </div>
          )}

          <div
            className={clsx(
              "flex items-center gap-2 whitespace-nowrap text-sm font-bold transition-all",
              {
                "max-w-96": isNavOpen,
                "max-w-0": !isNavOpen,
                "text-primary": isActiveApp,
                "text-foreground/75 group-hover:text-foreground": !isActiveApp,
              }
            )}
          >
            <div>{displayName}</div>
          </div>
        </div>
      </Link>
    </div>
  );
};

const MainNavigation: React.FC<{
  isNavOpen: boolean;
  setIsNavOpen: (isOpen: boolean) => void;
  activeApp: IAppInfo | undefined;
  toggleNavPinned: () => void;
  isNavPinned: boolean;
}> = ({ isNavOpen, setIsNavOpen, activeApp, isNavPinned }) => {
  const isOpen = isNavOpen || isNavPinned;

  return (
    <div
      onMouseEnter={() => {
        setIsNavOpen(true);
      }}
      onMouseLeave={() => {
        setIsNavOpen(false);
      }}
      className={clsx(
        "sticky top-0 z-50 box-border flex h-screen flex-col overflow-hidden overflow-y-auto border-r border-border bg-background px-4 py-6 transition-all",
        {
          "w-60": isOpen,
          "w-20": !isOpen,
        }
      )}
    >
      <StoreSelector isNavOpen={isOpen} />

      <div className="mt-6 flex flex-grow flex-col">
        {APPS.map((appInfo) => {
          const { key, display } = appInfo;

          const isActiveApp = activeApp?.key === key;

          if (!display) return null;

          return (
            <NavigationItem
              key={key}
              isActiveApp={isActiveApp}
              isNavOpen={isOpen}
              appInfo={appInfo}
            />
          );
        })}
      </div>
    </div>
  );
};

const DesktopNavigation: React.FC<{
  toggleNavPinned: () => void;
  isNavPinned: boolean;
}> = ({ toggleNavPinned, isNavPinned }) => {
  const pathname = usePathname();

  const [isNavOpen, setIsNavOpen] = useState(false);

  const currentApp = getCurrentApp(pathname);

  return (
    <MainNavigation
      isNavOpen={isNavOpen}
      setIsNavOpen={setIsNavOpen}
      activeApp={currentApp}
      toggleNavPinned={toggleNavPinned}
      isNavPinned={isNavPinned}
    />
  );
};

export default DesktopNavigation;
