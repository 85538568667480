"use client";

import { signOut } from "@revana/auth/react";
import { Button, type ButtonProps } from "@revana/ui";

import { APP_ROUTES } from "./Navigation/constants";

const SignoutButton: React.FC<ButtonProps> = (props) => {
  return (
    <Button
      onClick={() => signOut({ callbackUrl: APP_ROUTES.login })}
      {...props}
    >
      Sign Out
    </Button>
  );
};

export default SignoutButton;
