import { env } from "@web/env";

export const getBaseUrl = () => {
  if (typeof window !== "undefined") return window.location.origin;
  if (env.VERCEL_URL) return `https://${env.VERCEL_URL}`;

  // eslint-disable-next-line no-restricted-properties
  return `http://localhost:${process.env.PORT ?? 3000}`;
};

export const getProductionUrl = () => {
  if (typeof window !== "undefined") return window.location.origin;
  if (env.VERCEL_URL) return `https://${env.VERCEL_PROJECT_PRODUCTION_URL}`;

  // eslint-disable-next-line no-restricted-properties
  return `http://localhost:${process.env.PORT ?? 3000}`;
};

export const getWebhooksUrl = () => {
  if (typeof window !== "undefined") return window.location.origin;

  if (env.VERCEL_URL) return `https://${env.VERCEL_PROJECT_PRODUCTION_URL}`;

  return env.WEBHOOKS_URL;
};
