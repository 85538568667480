import {
  isValid,
  parseISO,
  differenceInDays,
  differenceInWeeks,
  differenceInMonths,
  differenceInYears,
} from "date-fns";

export interface DateRange {
  startDate: Date;
  endDate: Date;
}

function parseDate(date: string | null | undefined) {
  if (!date) {
    return undefined;
  }

  const parsed = parseISO(date);
  if (isValid(parsed)) {
    return parsed;
  }

  return undefined;
}

function calculateDateRangeDifference(
  currentPeriodDateRange: DateRange,
  previousPeriodDateRange: DateRange
): string {
  const currentStart = currentPeriodDateRange.startDate;
  const previousStart = previousPeriodDateRange.startDate;

  const daysDiff = differenceInDays(currentStart, previousStart);
  const weeksDiff = differenceInWeeks(currentStart, previousStart);
  const monthsDiff = differenceInMonths(currentStart, previousStart);
  const yearsDiff = differenceInYears(currentStart, previousStart);

  if (yearsDiff >= 1) {
    return `last ${yearsDiff === 1 ? "year" : `${yearsDiff} years`}`;
  } else if (monthsDiff >= 1) {
    return `last ${monthsDiff === 1 ? "month" : `${monthsDiff} months`}`;
  } else if (weeksDiff >= 1) {
    return `last ${weeksDiff === 1 ? "week" : `${weeksDiff} weeks`}`;
  } else {
    return `last ${daysDiff === 1 ? "day" : `${daysDiff} days`}`;
  }
}

export { parseDate, calculateDateRangeDifference };
